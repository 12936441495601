"use client";
import { createContext, PropsWithChildren, useContext, useEffect } from "react";
import { StaticData } from "./types";

const FlightStaticContext = createContext<StaticData>(undefined!);

const LEGACY_STATIC_KEYS = [
  "exp-data/flight-static-data-id-id",
  "exp-data/flight-static-data-en-id",
  "exp-time/flight-static-data-id-id",
  "exp-time/flight-static-data-en-id",
];

export default function FlightStaticProvider(
  props: PropsWithChildren<{ data: StaticData }>
) {
  // @todo: Remove this implmentation
  useEffect(() => {
    const isLegacy = checkLegacyLocalStorage();
    if (!isLegacy) return;
    try {
      LEGACY_STATIC_KEYS.forEach((key) => localStorage.removeItem(key));
    } catch {
      return;
    }
  }, []);
  return (
    <FlightStaticContext.Provider value={{ ...props.data }}>
      {props.children}
    </FlightStaticContext.Provider>
  );
}

function checkLegacyLocalStorage() {
  try {
    return LEGACY_STATIC_KEYS.some((key) => !!localStorage.getItem(key));
  } catch {
    return false;
  }
}

export function useFlightStaticData() {
  return useContext(FlightStaticContext);
}
