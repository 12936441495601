"use client";
import { withAuth } from "@ctv/core";
import { PropsWithChildren } from "react";

import Providers from "./_contexts/Providers";
import { StaticData } from "./_contexts/flight-static-data";

type Props = {
  staticData: StaticData;
};

export default withAuth<PropsWithChildren<Props>>(function Content({
  children,
  staticData,
}) {
  return <Providers staticData={staticData}>{children}</Providers>;
});
