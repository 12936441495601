import lodash from "lodash";

import { serverApi } from "@ctv/core";
import { Locale } from "@ctv/shared-core/src";

import { AirlineMap, AirportMap, AreaAirportsMap } from "./types";

export const AIRLINE_DATA_API = "/api/v2/search/flight/static/airlines";
export type AirlineDataResponse = {
  airlines: Array<{
    airlineCode: string;
    airlineName: string;
    logoUrl: string;
  }>;
};

export const AIRPORT_DATA_API = "/api/v2/search/flight/static/airports";
export type AirportDataResponse = {
  airports: Array<{
    airportCode: string;
    airportIcaoCode: string;
    areaCode: string;
    city: string;
    countryCode: string;
    countryId: string;
    internationalAirportName: string;
    timeZone: string;
  }>;
};

export const INITIAL_FLIGHT_STATIC_DATA = {
  airlineMap: {},
  airportMap: {},
  areaAirportsMap: {},
};

export async function getFlightStaticData(locale: Locale) {
  const getAirlineData = serverApi<AirlineDataResponse, never>(
    {
      domain: "search-production",
      method: "post",
      path: AIRLINE_DATA_API,
      cacheTime: 60 * 2,
      showLog: true,
    },
    locale
  );

  const getAirportData = serverApi<AirportDataResponse, never>(
    {
      domain: "search-production",
      method: "post",
      path: AIRPORT_DATA_API,
      cacheTime: 60 * 2,
      showLog: true,
    },
    locale
  );

  const staticData = lodash.cloneDeep(INITIAL_FLIGHT_STATIC_DATA);

  try {
    const [airlineRes, airportRes] = await Promise.all([
      getAirlineData(),
      getAirportData(),
    ]);

    if (airlineRes.success) {
      const { airlines } = airlineRes.data;

      staticData.airlineMap = airlines.reduce((obj, airline) => {
        obj[airline.airlineCode] = airline;

        return obj;
      }, {} as AirlineMap);
    } else {
      throw new Error("Failed to fetch airline data");
    }

    if (airportRes.success) {
      const { airports } = airportRes.data;

      staticData.airportMap = airports.reduce((obj, airport) => {
        obj[airport.airportCode] = {
          airportCode: airport.airportCode,
          city: airport.city,
          countryId: airport.countryId,
          countryCode: airport.countryCode,
          internationalAirportName: airport.internationalAirportName,
        };

        return obj;
      }, {} as AirportMap);

      staticData.areaAirportsMap = airports.reduce((obj, airport) => {
        const areaAiports = obj[airport.areaCode];

        if (airport.airportCode !== airport.areaCode) {
          const newValue =
            typeof areaAiports === "string" ? `${areaAiports}|` : "";

          obj[airport.areaCode] = newValue.concat(airport.airportCode);
        }

        return obj;
      }, {} as AreaAirportsMap);
    } else {
      throw new Error("Failed to fetch airport data");
    }
  } catch (error) {
    console.error(`[LOG ERROR] getFlightStaticData: `, error);
  }

  return staticData;
}
