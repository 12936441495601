import { Airport, StaticData } from "./types";

/**
 * Find Airport from Area Code
 * @param areaCode string
 * @param staticData StaticData
 * @returns Airport | undefined
 */
export function findAirportsFromAreaCode(
  areaCode: string,
  staticData: StaticData
): Airport | undefined {
  const airportCodes = staticData.areaAirportsMap[areaCode];
  if (airportCodes) {
    const [code] = airportCodes.split("|");
    return staticData.airportMap[code]!;
  }
  console.error(
    `[LOG ERROR] findAirportsFromAreaCode: Can't find airport city Arena Code (${areaCode})`
  );
}

/**
 * @param code may be Airport Code or Area Code
 */
export function guessAirportName(
  code: string,
  staticData: StaticData,
  allAiportLabel: string
): string {
  const airport = staticData.airportMap[code];
  if (airport) {
    return `${airport.city} (${code}) - ${airport.internationalAirportName}`;
  }

  const areaAirports = findAirportsFromAreaCode(code, staticData);
  if (areaAirports) {
    return `${areaAirports.city} (${allAiportLabel})`;
  }

  return code;
}

/**
 * Guess Airport City based on Airport Code or Area Code
 * @param code string
 * @param staticData StaticData
 * @returns string
 */
export function guessAirportCity(code: string, staticData: StaticData): string {
  const airport = staticData.airportMap[code];
  if (airport) {
    return `${airport.city} (${code})`;
  }

  const areaAirports = findAirportsFromAreaCode(code, staticData);
  if (areaAirports) {
    return `${areaAirports.city} (${code})`;
  }

  console.error(
    `[LOG ERROR] guessAirportCity: Can't find airport city with Airport/Arena Code (${code})`
  );
  return code;
}

export function getCityWithAirportCode(
  airportCode: string,
  staticData: StaticData
): string {
  const airport = staticData.airportMap[airportCode];
  if (airport) {
    return `${airport.city} (${airportCode})`;
  }

  return airportCode;
}

export function getAirportNameWithAirportCode(
  airportCode: string,
  staticData: StaticData
): string {
  const airport = staticData.airportMap[airportCode];
  if (airport) {
    return `${airport.internationalAirportName} (${airportCode})`;
  }

  return airportCode;
}

export function getAirportName(
  airportCode: string,
  staticData: StaticData
): string {
  const airport = staticData.airportMap[airportCode];
  if (airport) {
    return airport.internationalAirportName;
  }

  return airportCode;
}

export function getAirportCity(
  airportCode: string,
  staticData: StaticData
): string {
  const airport = staticData.airportMap[airportCode];
  if (airport) {
    return airport.city;
  }

  return airportCode;
}
